// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from 'crypto-js';

export const useEncryptData = (text) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      'd6439258f1162b303d28a295a1087b0314e166faeddfdb8ae0e0aec7036b4bdff594a7848e186f02db5f19d7ba616969423c53d64e3c3136cfcc476ac4b998e6'
    ).toString();
    return encryptedData;
  } catch (error) {
    console.log('something went wrong :', error);
    return error;
  }
};

export const useDecryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      text,
      'd6439258f1162b303d28a295a1087b0314e166faeddfdb8ae0e0aec7036b4bdff594a7848e186f02db5f19d7ba616969423c53d64e3c3136cfcc476ac4b998e6'
    );

    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return {
      token: decryptedData,
      isSuccess: true,
    };
    // use the decrypted and parsed data here
  } catch (error) {
    if (error.message === 'expected end of JSON input') {
      console.warn('The decrypted data is not valid JSON');
    } else {
      console.warn('An error occurred during decryption and parsing:', error.message);
    }
    return {
      token: '',
      isSuccess: false,
    };
  }
};
